/* all you need */
@font-face {
  font-family: "Bobby Jones";
  src: url("/fonts/Bobby-Jones-Soft.woff2") format("woff2");
}
@font-face {
  font-family: "Challenge Bold";
  src: url("/fonts/Challenge-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Founders Medium";
  src: url("/fonts/FoundersGrotesk-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Founders Regular";
  src: url("/fonts/FoundersGrotesk-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Goudy";
  src: url("/fonts/Goudy-Heavyface-Condensed-Regular.woff2") format("woff2");
}

// Links
//
// Style anchor elements.

.text-start {
  color: white;
}

$link-color:                              $primary;
$link-decoration:                         underline;
$link-shade-percentage:                   20%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                   null;

$stretched-link-pseudo-element:           after;
$stretched-link-z-index:                  1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;

$rfs-factor: 2.5; // 10;
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Founders Regular";
$font-family-bold: "Founders Medium";
$font-family-buttons: "Bobby Jones";
$font-family-details: "Goudy";
$font-family-titles: "Goudy";

.ff-body {
  font-family: $font-family-sans-serif;
}

.ff-bold {
  font-family: $font-family-bold;
}

.ff-buttons,
.btn {
  font-family: $font-family-buttons !important
}

.ff-details {
  font-family: $font-family-details !important
}

.ff-titles {
  font-family: $font-family-titles;
}


//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
//$font-family-base:            var(--#{$variable-prefix}font-sans-serif);
//$font-family-code:            var(--#{$variable-prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null;

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @return calc($pixels / $context) * 1em;
}

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

//$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.5;
$line-height-sm:              1.25;
$line-height-lg:              2;


$h1-font-size:                  em(220); // $font-size-base * 2.5;
$h2-font-size:                  em(56); // $font-size-base * 2;
$h3-font-size:                  em(32); // $font-size-base * 1.75;
$h4-font-size:                  em(24); // $font-size-base * 1.5;
$h5-font-size:                  em(20); // $font-size-base * 1.25;
$h6-font-size:                  em(16); // $font-size-base;

// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-font-sizes: (
  1: em(96),
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$display-font-family: null;
$display-font-style:  null;
$display-font-weight: 300;
// $display-line-height: $headings-line-height;

// scss-docs-start type-variables
$lead-font-size:              em(24); // $font-size-base * 1.25;
$lead-font-weight:            var(--lead-font-weight); // 300;

$small-font-size:             .875em;

$sub-sup-font-size:           .75em;

$text-muted:                  $gray-600;

$initialism-font-size:        $small-font-size;

$blockquote-margin-y:         $spacer;
$blockquote-font-size:        $font-size-base * 1.25;
$blockquote-footer-color:     $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y:                 $spacer;
$hr-color:                    inherit;
$hr-height:                   $border-width;
$hr-opacity:                  .25;

$legend-margin-bottom:        .5rem;
$legend-font-size:            1.5rem;
$legend-font-weight:          null;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;
